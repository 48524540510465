

import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'
  


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/App'), universalOptions)
const t_1 = universal(import('../src/404'), universalOptions)


export const template404ID = 1

// Template Map
export default [
  t_0,
t_1
]
