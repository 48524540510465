import React from 'react'
import { compose } from 'recompose'
import { withSiteData } from 'react-static'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Logo from 'LogoSVG'
import resume from '../public/resume.json'

const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontSize: 18,
		padding: theme.spacing.unit * 4,
	},
	logo: {
    width: '150px',
    height: '150px',
		marginBottom: theme.spacing.unit * 2,
  },
})

const BasicInfo = ({ classes, className, theme }) => (
	<div className={classnames(classes.root, className)}>
		<Logo fill={theme.palette.background.paper} className={classes.logo} />
		<Typography variant="h6" color="textSecondary">{resume.basics.name}</Typography>
		<Typography variant="subtitle2" color="textSecondary">{resume.basics.label}</Typography>
	</div>
)

export default compose(
	withSiteData,
	withStyles(styles, { withTheme: true }),
)(BasicInfo)
