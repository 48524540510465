import { deepOrange, red, cyan } from '@material-ui/core/colors'

export default {
  palette: {
		type: 'dark',
    primary: cyan,
    secondary: deepOrange,
    error: red,
  },
  typography: {
    useNextVariants: true,
		fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		fontSize: 18,
  },
}
