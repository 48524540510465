import React from 'react'
import { hot } from 'react-hot-loader'
import { compose, lifecycle } from 'recompose'
import { withSiteData } from 'react-static'
import { SnackbarProvider } from 'notistack'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import BasicInfo from 'BasicInfo'
import ContactForm from 'ContactForm'
import theme from 'theme'

const muiTheme = createMuiTheme(theme)

const styles = {
  '@global': {
    img: {
      maxWidth: '100%',
    },
		'html, body, #root': {
			height: '100%',
		},
    body: {
      ...muiTheme.typography.body2,
    },
  },
	root: {
		height: '100%',
	},
	content: {
		marginTop: muiTheme.spacing.unit * 10,
		marginBottom: muiTheme.spacing.unit * 4,
	}
}


const App = ({ classes }) => (
	<Grid
		className={classes.root}
    container
    alignItems="flex-start"
    justify="center"
    wrap="wrap"
  >
		<Grid className={classes.content} item xs={12} md={3}>
			<CssBaseline />
			<BasicInfo />
			<ContactForm />
		</Grid>
	</Grid>
)

const AppWithStyles = compose(
	withSiteData,
	withStyles(styles),
	lifecycle({
	  componentDidMount() {
			// Remove the server-side injected CSS.
			const jssStyles = document.getElementById('jss-server-side')
	    if (jssStyles && jssStyles.parentNode) {
	      jssStyles.parentNode.removeChild(jssStyles)
	    }
	  }
	})
)(App)

export default hot(module)(() => (
	<SnackbarProvider>
		<MuiThemeProvider theme={muiTheme}>
			<AppWithStyles />
		</MuiThemeProvider>
	</SnackbarProvider>
))
