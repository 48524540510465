import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default ({ fill, ...props }) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 870 870">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="logo" fillRule="nonzero" fill={fill}>
          <path d="M294.9,589.8 L574.9,589.8 C574.9,744.4 449.6,869.8 294.9,869.8 L294.9,589.8 Z M589.8,0 L589.8,280 L869.8,280 C869.8,125.3 744.4,0 589.8,0 Z M280,280 L280,0 L0,0 C0,154.6 125.3,280 280,280 Z M294.9,280 L574.9,280 L574.9,0 L294.9,0 L294.9,280 Z M589.8,574.9 C744.4,574.9 869.8,449.6 869.8,294.9 L589.8,294.9 L589.8,574.9 Z M294.9,574.9 L574.9,574.9 L574.9,294.9 L294.9,294.9 L294.9,574.9 Z" id="Shape" />
        </g>
      </g>
    </svg>
  </SvgIcon>
)
