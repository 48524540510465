import React from 'react'
import es6Promise from 'es6-promise'
import 'isomorphic-fetch'
import omitBy from 'lodash/omitBy'
import revalidator from 'revalidator'
import { compose, withState, withHandlers } from 'recompose'
import { withSnackbar } from 'notistack'
import classnames from 'classnames'
import { MuiThemeProvider, withStyles, createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import TextField from '@material-ui/core/TextField'
import theme from 'theme'

const muiTheme = createMuiTheme({
	...theme,
	typography: {
		...theme.typography,
		fontSize: 14,
  },
})

es6Promise.polyfill()

const initialValues = {
	name: '',
	email: '',
	message: '',
	botFly: '',
}

const validate = (fields) => revalidator.validate(fields, {
    properties: {
      name: {
        type: 'string',
        required: true,
				minLength: 1,
				message: 'This is a required field',
      },
      email: {
        type: 'string',
				format: 'email',
				required: true,
				messages: {
				 required: 'This is a required field',
				 format: 'Must be a valid email address',
			 },
      },
      message: {
				type: 'string',
        required: true,
				minLength: 1,
				message: 'This is a required field',
      }
    }
  })

const encode = (data) => Object.keys(data)
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join("&")

const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection:'column',
		alignItems: 'center',
		marginLeft: theme.spacing.unit * 4,
		marginRight: theme.spacing.unit * 4,
	},
	message: {
		marginBottom: theme.spacing.unit * 2,
	},
	botFly: {
		display: 'none',
	},
	actions: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	button: {
		marginLeft: theme.spacing.unit / 2,
		marginRight: theme.spacing.unit / 2,
	}
})

const ContactForm = ({
	classes, className, visible, showForm, hideForm, send, fields, touched, changeField, touchField
}) => {
	const validation = validate(fields)
	const errors = validation.errors.reduce((errors, error) => {
		errors[error.property] = error.message
		return errors
	}, {})
	return (
		<form name="contact" netlify="true" netlify-honeypot="bot-fly" className={classnames(classes.root, className)}>
			<input type="hidden" name="form-name" value="contact" />
			<Collapse in={visible}>
				<React.Fragment>
					<TextField
						id="name-input"
						required
						error={visible && touched.name && !!errors.name}
						helperText={(touched.name && errors.name) || ' '}
						className={classes.name}
						label="Name"
						name="name"
						value={fields.name}
						onChange={changeField('name')}
						onBlur={touchField('name')}
						margin="dense"
						variant="outlined"
						fullWidth
					/>
					<TextField
						required
						error={visible && touched.email && !!errors.email}
						helperText={(touched.email && errors.email) || ' '}
						className={classes.email}
						label="Email"
						name="email"
						value={fields.email}
						onChange={changeField('email')}
						onBlur={touchField('email')}
						margin="dense"
						variant="outlined"
						fullWidth
					/>
					<TextField
						required
						error={visible && touched.message && !!errors.message}
						helperText={(touched.message && errors.message) || ' '}
						className={classes.message}
						label="Message"
						name="message"
						multiline
						value={fields.message}
						onChange={changeField('message')}
						onBlur={touchField('message')}
						margin="dense"
						variant="outlined"
						fullWidth
					/>
					<TextField
						required
						error={visible && touched.botFly && !!errors.botFly}
						helperText={(touched.botFly && errors.botFly) || ' '}
						className={classes.botFly}
						label="BotFly"
						name="bot-fly"
						value={fields.botFly}
						onChange={changeField('botFly')}
						onBlur={touchField('botFly')}
						margin="dense"
						variant="outlined"
						fullWidth
					/>
				</React.Fragment>
			</Collapse>
			{visible ? (
				<div className={classes.actions}>
					<Button
						size="small"
						onClick={hideForm}
						className={classes.button}
					>
						Cancel
					</Button>
					<Button
						variant="outlined"
						size="small"
						disabled={!validation.valid}
						onClick={send}
						className={classes.button}
					>
						Send
					</Button>
				</div>
			) : (
				<Button
					variant="outlined"
					size="small"
					onClick={showForm}
					className={classes.button}
				>
					Contact
				</Button>
			)}
		</form>
	)
}

const WrappedContactForm = compose(
	withStyles(styles, { withTheme: true }),
	withSnackbar,
	withState('visible', 'setVisibility', false),
	withState('touched', 'setTouched', {}),
	withState('fields', 'setFields', initialValues),
	withHandlers({
    showForm: ({ theme, setVisibility }) => () => {
			setVisibility(true)
			setTimeout(() => {
				document.getElementById('name-input').focus()
			}, theme.transitions.duration.standard)
		},
		hideForm: ({ setVisibility }) => () => setVisibility(false),
    send: ({
			fields,
			setVisibility,
			setFields,
			setTouched,
			enqueueSnackbar,
		}) => async () => {
			try {
				const res = await fetch("/", {
	        method: "POST",
	        headers: { "Content-Type": "application/x-www-form-urlencoded" },
	        body: encode({ "form-name": "contact", ...omitBy(fields, f => !f) })
	      })
				if(res.ok) {
					setVisibility(false)
					enqueueSnackbar('Your message has been sent.', { variant: 'success' })
					setTouched({})
					setFields(initialValues)
				} else {
					enqueueSnackbar('There was a problem submitting your message.', { variant: 'error' })
				}
			} catch(e) {
				enqueueSnackbar('There was a problem submitting your message.', { variant: 'error' })
			}

		},
		touchField: ({ setTouched, touched }) => field => () => setTouched({ ...touched, [field]: true }),
		changeField:  ({ setFields, fields }) => field => e => setFields({ ...fields, [field]: e.target.value }),
  }),
)(ContactForm)

export default props => (
	<MuiThemeProvider theme={muiTheme}>
		<WrappedContactForm {...props} />
	</MuiThemeProvider>

)
